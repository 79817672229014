import { registerPaymentMethod } from '@woocommerce/blocks-registry';
import { getSetting } from '@woocommerce/settings';
import { decodeEntities } from '@wordpress/html-entities';

const settings = getSetting( 'przelewy24_data', {} );

const A_content = () => {
    return decodeEntities( '' );
};

const A_label = ( props ) => {
    const { PaymentMethodLabel } = props.components;
    return <PaymentMethodLabel text={ settings.gateway_text } />;
};

registerPaymentMethod({
    name: settings.gateway_name,
    label: <A_label/>,
    ariaLabel: settings.gateway_text,
    content: <A_content/>,
    edit: <A_content/>,
    canMakePayment: () => true,
});
